import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
// 共享数据
export default new Vuex.Store({
  state: {

    carNum: localStorage.getItem("userInfo") ? localStorage.getItem(`${JSON.parse(localStorage.getItem("userInfo")).username}carNum`) : '',
    address: localStorage.getItem('address'),
    cityCode: localStorage.getItem('cityCode'),
    nickname: localStorage.getItem('nickname'),
    //缓存页面
    keepAlive: [],
    // 
  },
  getters: {
    keepAlive: state => state.keepAlive
  },
  mutations: {
    // 修改购物车数据
    setCarNum(state, data) {
      state.carNum = data
    },
    // 修改用户数据
    setNickname(state, data) {
      state.nickname = data
    },
    // 修改地址
    setAddress(state, data) {
      state.address = data
    },
    SET_KEEP_ALIVE: (state, keepAlive) => {
      state.keepAlive = keepAlive
    },
  },
  actions: {
  },
  modules: {
  }
})
