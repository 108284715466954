<template >
  <div class="loading" >
    <div>
      <van-loading :color="backcolor" type="spinner" vertical> 加载中... </van-loading>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    topH: {
      default: 0
    },
    backcolor: {
      type: String,
      default: '#D33B2E'
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {
  },
  mounted() { },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.loading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
    // background-color: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>