<template>
  <div class="container app-wrapper">
    <!-- <div  :style="{backgroundImage: 'url(https://img0.baidu.com/it/u=739245871,522124166&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500)',with:'100%', height: '6.5rem',borderRadius:'0.5rem' }" ></div> -->
    <loading v-if="loading"></loading>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :disabled="true" v-else>
      <van-sticky :offset-top="0">
        <head-child class="head" :change="true">
          <template #left>
            <div class="flex jcc t-a-c" v-if="boo2" @click="Switch">
              <div class="ellipsis mr-5">
                {{ address }}
              </div>
              <div>
                <i class="iconfont icon-iconfontarrows1"></i>
              </div>
            </div>
            <div v-else @click="goback">
              <van-icon size="1.5rem" name="arrow-left" />
            </div>
          </template>
          <template #middle>
            <van-search v-model="value" placeholder="搜素商品" @focus="seach1" shape="round"
              />
          </template>
          <template #right>
            <div @click="seach">搜索</div>
            <!-- <div @click="delSeach" v-else>取消</div> -->
          </template>
        </head-child>
      </van-sticky>

      <!-- 正常页面 -->
      <div v-if="boo2" style="padding-bottom: 3.5rem; background: transparent">
        <!-- 轮播图 -->
        <head-index v-if="headData" :headData="headData"
          style="height: 8.14rem; margin-left: 0.5rem; margin-right: 0.5rem"></head-index>
        <nav-image ref="category"></nav-image>
        <div v-if="lableData" style="
            margin: 10px 3px 5px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
          ">
        </div>
        <!-- <lable-image v-if="lableData" :data-list="lableData?.copyShowImgList"></lable-image> -->
        <div v-if="lableData?.length > 2"
          style="height: 16.5rem; margin: 0 0.5rem 0.3rem 0.5rem; ;display: flex;flex-direction: row;">
          <van-image style="flex: 1; height: 100%; " :src="lableData[0].showImgId.showImg" radius="5"
            @click="queryTopicBoolean(lableData[0])"></van-image>
          <div style="flex: 1; display: flex;flex-direction: column;margin-left: 0.5rem;">
            <van-image style="width: 100%; height: 8rem; margin-bottom: 0.5rem ; flex: 1;"
              :src="lableData[1].showImgId.showImg" radius="5" @click="queryTopicBoolean(lableData[1])"></van-image>
            <van-image style="width: 100%; height: 8rem; flex: 1;" :src="lableData[2].showImgId.showImg" radius="5"
              @click="queryTopicBoolean(lableData[2])"></van-image>
          </div>
        </div>
        <div>
          <TwoLevelProductCategory ref="towLevelCategoryRef" @change="handleTwoLevel" @loadedAfter="handleTwoLevel">
          </TwoLevelProductCategory>
          <van-list v-model="loading2" :finished="finished" finished-text="没有更多了" @load="nextPage">
            <goods-list-com :DataList="list"> </goods-list-com>
          </van-list>
        </div>
      </div>
      <!-- 搜索页面 -->
      <seachview v-else ref="seach" :value="value" @value="seachvalue" :type="2"></seachview>
    </van-pull-refresh>
    <!-- 底部 -->
    <AllNav></AllNav>
  </div>
</template>

<script>
import { Toast } from "vant";
import HeadChild from "../components/HeadChild";
import HeadIndex from "../components/index/HeadIndex.vue";
import Recommended from "../components/index/Recommended.vue";
import NavImage from "../components/index/NavImage.vue";
import LableImage from "../components/cake/LableImage.vue";
import IndexFloor from "../components/index/IndexFloor.vue";
import NaigateTool from "../components/index/NavigateTool";
import Seachview from "../components/index/Seachview.vue";
import GoodsListCom from "../components/GoodsListCom.vue";
import AllNav from "../components/footnav/AllNav.vue";
import TwoLevelProductCategory from "../components/home/TwoLevelProductCategory.vue";
let initViewfinish = false
export default {
  name: "Home",
  props: {},
  data() {
    return {
      cardShowFlag: null, // 卡类型
      // 容器宽度百分比
      width: 100,
      // 滑轮值
      range: 100,
      //是否根据容器尺寸自动计算重绘
      autoResize: true,
      //列数
      col: 2,
      //是否始终填满容器
      fillBox: false,
      // //列宽-有指定列数则此属性失效
      // colWidth: (pc ? window.innerHeight : window.innerWidth) / 2,
      // // 列数和列宽模式切换
      // mode: 0,
      list: [],
      recoData: [],
      loading2: false, // 商品列表是否处于加载状态
      finished: false, // 是否加载完了
      refreshing: false,
      checkList: [], // 列表数据
      tabindexL: 1,
      loading: false,
      category: [
      ],
      advertesPicture: [],
      headData: [],
      lableData: [],

      form: {
        cardId: "",
        current: 1, // 当前页码
        size: 10, // 每页个数
        productName: "",
        category1Id: "",// 二级分类菜单
        cityCode: '',
        upgradeFlag: localStorage.getItem("upgradeFlag"),
        bathId: localStorage.getItem("bathId"),
        id: '',
        hotType: ''
      },

      city: null,
      value: null,
      address: null,
      // 非搜索状态
      boo2: true,
      // 搜索 取消
      boo3: true,
      // 刷新
      isLoading: false,

    };
  },
  components: {
    TwoLevelProductCategory,
    HeadIndex,
    NavImage,
    Recommended,
    HeadChild,
    IndexFloor,
    Seachview,
    NaigateTool,
    LableImage,
    GoodsListCom,
    AllNav
  },
  methods: {
    /**
     * 活动标签点击执行函数
     */
    handleActivityLabelClick(val) {
      if (val.showType == '1') {
        this.$router.push({
          path: "/LableList",
          query: { activityId: val.showImgId.id, type: 'B' }
        });
      } else if (val.showType == '2') {
        this.$router.push({
          path: "/LableClassList",
          query: { activityId: val.showImgId.id, type: 'B' }
        });
      } if (val.showType == '3') {
        if (val.productId) {
          //  传递参数
          this.$router.push({
            path: "/Details",
            query: {
              id: val.productId,
              brandId: val.brandId,
            },
          });
        } else {
          this.$Toast('敬请期待')
        }
      }
    },
    /**
     * 二级分类加载完成后或选择触发方法
     */
    handleTwoLevel(record) {
      if (record.data.isShowD) {
        this.form.id = record.data.id
        this.form.hotType = 'D'
      } else {
        this.form.id = ''
        this.form.hotType = ''
      }
      this.finished = false
      this.form.category1Id = record.data ? record.data.id : "";
      this.onRefresh();
    },
    goDetail(val) {
      // if (val.flag == "1") {
      this.$router.push({
        path: "/Details",
        query: {
          id: val.id,
          specId: val.specId,
          brandId: val.brandId,
          category1Id: this.form.category1Id,
        }
      });
    },
    loadmore(index) {
      console.log('index', index)
    },
    nextPage() {
      // 加载下一页
      if (this.list.length >= 10) {
        this.loading = false;
        this.form.current += 1;
        this.getData();
      }
    },
    getTabIndex(index) {
      this.tabindexL = index;
    },
    // 刷新
    onRefresh() {
      this.form.current = 1;
      var timer = setInterval(() => {
        this.getData();
        clearInterval(timer);
      }, 200)
    },
    getData() {
      this.$nextTick(()=> {
        this.$api
        .queryProductInfoHomeNew(this.form)
        .then((res) => {
          if (res.code == 1) {
            Toast(res.msg);
            return;
          }
          if (this.form.current == 1) {
            this.list = res.data.records;
          } else {
            this.list.push(...res.data.records);
          }
          this.loading = false;
          this.loading2 = false;
          if (this.form.current >= res.data.pages) {
            this.finished = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.loading2 = false;
        });
      })
     
      
    },
    getBanner() {
      let form = {
        cardId: this.form.cardId,
        cityCode: this.form.cityCode,
        upgradeFlag: this.form.upgradeFlag,
        bathId: this.form.bathId,
        hotType: "A",
        showPositionCode: "0"
      }

      this.$api
        .getQueryRotation(form)
        .then((res) => {
          this.headData = res.data
          this.loading = false;
        })
        .catch((err) => {
        });
    },
    getQueryLabel() {
      let form = {
        cardId: this.form.cardId,
        cityCode: this.form.cityCode,
        upgradeFlag: this.form.upgradeFlag,
        bathId: this.form.bathId,
        hotType: "B",
        showPositionCode: "0"
      }
      this.$api
        .getQueryRotation(form)
        .then((res) => {
          this.lableData = res.data
        })
        .catch((err) => {
        });
    },

    queryTopicBoolean(item) {
      let form = {
        cardId: this.form.cardId,
        cityCode: this.form.cityCode,
        upgradeFlag: this.form.upgradeFlag,
        bathId: this.form.bathId,
        size: 10,
        current: 1,
        hotType: "B",
        id: item.showImgId.id
      }
      this.$api
        .queryTopicBoolean(form)
        .then((res) => {
          if (res.data) {
            if (res.data.flag) {
              item.flag = true
              item.showType = res.data.showType
              this.handleActivityLabelClick(item)
            } else {
              Toast('敬请期待')
            }
          } else {
            Toast(res.msg)
          }
        })
        .catch((err) => {
        });
    },
    // 位置选择
    Switch() {
      this.$router.push("SelectAdderss");
    },
    // 调用子组件的方法
    seach() {
      this.$refs.seach.seach(this.value);
      this.boo3 = false;
    },
    // 取消
    delSeach() {
      this.value = null;
      this.boo3 = true;
      this.$refs.seach.history = true;
      this.$refs.seach.getHistroy();
    },
    // 输入框获取焦点
    seach1() {
      this.$router.push('SearchGoods')
    },

    // 返回
    goback() {
      this.boo2 = true;
      this.value = null
      this.$nextTick(() => {
        this.$refs.towLevelCategoryRef.init()
        this.$refs.category.init()
      })
    },
    //子组件分发的事件  点击历史记录重新给输入框赋值
    seachvalue(val) {
      this.value = val;
    },
    repaints() {
      this.$refs.waterfall.repaints(0, 0.4);
    },
    initView() {
      this.value = null
      this.boo2 = true
      this.form.cardId = localStorage.getItem("cardId");
      this.form.cityCode = localStorage.getItem('cityCode')
      this.cardShowFlag = this.$route.query.cardShowFlag;
      this.getBanner();
      this.getQueryLabel();
      // this.getData() // 此处商品加载放入到二级分类加载完毕回调中
      // // 是否有手动选择地址
      this. address = localStorage.getItem("address");
      this.$nextTick(() => {
        this.$refs.towLevelCategoryRef.init()
        this.$refs.category.init()
      })
    }
  },
  created() {
    if (initViewfinish) {
      initViewfinish = false
      this.initView()
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0
    }
  },
  inject: ["reload"],
  beforeRouteEnter(to, from, next) {
    to.meta.keepAlive = true
    if (from.path !== "/Details" && from.path !== '/GoodsList' && from.path !== '/SearchGoods') {
      next((vm) => {
        vm.$nextTick(() => {
          vm.initView()
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0
        })
      });
    } else {
      initViewfinish = true
      next();
    }
  },
  mounted() {
  },
  computed: {},
  watch: {
    // 输入框的值
    value(val, oldval) {
      if (oldval !== "") {
        this.boo3 = true;
      }
    },
    // 地址
    address() {
      return this.$store.state.address;
    },
    '$route': function () {
      this.$waterfall.forceUpdate()
    }
  }
};
</script>

<style lang="scss" scoped>
.container-water-fall {

  /* // padding: 0 28px; */
  padding: 0 3%;
  width: 100vw;
  box-sizing: border-box;
  /* background: #fafafa !important; */
}

.cell-item {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 5% 0;
}

.cell-item img {
  width: 100%;
  height: auto;
  display: block;
}

.head {
  padding: 0.5rem 0.2rem;
  // height: 2rem;
  color: white;
}

.container {
  // background-image: linear-gradient(180deg,
  //     #ff9669,
  //     #ff9669,
  //     rgba(210, 59, 46, 0));
  background-image: url("../assets/images/home_back.jpg");
  background-size: 100% 12rem;
  background-repeat: no-repeat;
}

.van-search__content {
  background-color: rgb(255, 255, 255);
}
.van-search {
  background-color: rgb(255, 255, 255, 0);
}

.gridDivImg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;
  height: 12rem;
}

.empty {
  transform: translateY(50%);
}

/*瀑布流对应布局开始*/
.main {
  height: max-height;
  flex: auto;
  overflow-y: auto;
  overflow-x: hidden;

  /*瀑布流对应布局开始*/
  .waterfall-item {
    margin: 5px;
    background: white;
    box-sizing: border-box;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    padding-bottom: 1rem;
  }

  .waterfall-item img {
    width: 100%;
    display: block;
  }

  .bounceIn {
    animation-name: bounceIn;
    animation-duration: 0.6s;
    animation-timing-function: linear;
    transform-origin: center center;
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.3);
      -ms-transform: scale(0.3);
      transform: scale(0.3);
    }

    50% {
      opacity: 1;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
    }

    70% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
}

.texttitle {
  font-size: 0.875rem;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 2.5rem;

  padding-top: 0.5rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.textFoot {
  font-size: 1.125rem;
  color: #d23b2e;
  letter-spacing: 0;
  font-weight: 500;
  height: 2rem;
  margin-left: 0.75rem;
  margin-top: 0.25rem;
  margin-bottom: 0.3125rem;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: none;
}

/*瀑布流对应布局结束*/
</style>
